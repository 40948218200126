import React from "react";
import {AweComponent, connectComponent} from "./AweComponent";
import {OverlayPanel} from "primereact/overlaypanel";
import {classNames, clickDropdown} from "../utilities/components";
import {Components} from "./AweComponents";
import {getIconCode} from "../utilities";

import "./AweAvatar.less";
import {Avatar} from "primereact/avatar";
import {Badge} from "primereact/badge";

class AweAvatar extends AweComponent {

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    clickDropdown(e, this.props, this.op);
  }

  render() {
    const {t, elementList, attributes, model} = this.props;
    const {values} = model;
    const {style, icon, disabled, label, size, unit, image, showLabel = true} = attributes;
    const classes = classNames("p-button-rounded", "p-button-text", "p-button-secondary", {[`p-button-${size}`]: size}, style);

    let computedLabel = values?.[0].label || label;
    let computedImage = values?.[0].image || image;
    let computedIcon = values?.[0].icon || icon;
    let computedUnit = values?.[0].unit || unit;
    let unitBadge = computedUnit && <Badge value={computedUnit}/>;
    let labelSpan = showLabel && <span className={"avatar-name"} >{t(computedLabel)}</span>;
    return <>
      <div className="avatar-component p-overlay-badge" onClick={this.onClick} role="button" onKeyDown={this.onClick}>
        <Avatar
          id={this.props.address.component}
          type="button"
          className={classes}
          shape={"circle"}
          image={computedImage}
          icon={!computedImage && getIconCode(computedIcon, "p-button-icon p-c p-button-icon-left")}
          disabled={this.props.disabled || disabled}
          label={!computedImage && !computedIcon && (computedLabel || "").charAt(0).toUpperCase()}
          title={t(computedLabel)}>
          {unitBadge}
        </Avatar>
        {labelSpan}
      </div>
      <OverlayPanel ref={(el) => this.op = el} dismissable className={"info-dropdown"}>
        {elementList.map((node, index) => Components(node, index))}
      </OverlayPanel>
    </>;
  }
}

export default connectComponent(AweAvatar);
